@import "./styles/colors.css";
@import "./styles/constants.css";
@import "./styles/icons.css";

/* global styles
css modules not applied */

html {
    line-height: 1.5715;
    font-weight: 400;
    font-size: 14px;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
}

h1,
h2 {
    text-align: center;
    font-weight: 400;
}

h1 {
    color: var(--light-theme-heading1-color);
    font-size: 38px;
}

h2 {
    color: var(--light-theme-heading2-color);
    font-size: 32px;
}

h3 {
    font-size: 21px;
    font-weight: 400;
}

h4 {
    font-size: 19px;
    font-weight: 500;
}

h5 {
    font-size: 16px;
    margin: 0px;
}

header {
    padding: 0px;
}

img {
    width: 100%;
}

p {
    font-size: 16px;
    margin: 0;
}

canvas:focus {
    outline: none;
}

@font-face {
    font-family: "icomoon";
    src: url("./assets/fonts/icomoon.eot?jthex9");
    src: url("./assets/fonts/icomoon.eot?jthex9#iefix")
            format("embedded-opentype"),
        url("./assets/fonts/icomoon.ttf?jthex9") format("truetype"),
        url("./assets/fonts/icomoon.woff?jthex9") format("woff"),
        url("./assets/fonts/icomoon.svg?jthex9#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.ant-btn:focus,
.ant-btn:hover {
    border-color: var(--dark-theme-btn-hover-bg);
}

.ant-tooltip-inner,
.ant-popover-inner {
    background: var(--dark-theme-tooltip-bg) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 4px var(--black);
    opacity: 1;
}

.icon-moon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "icomoon" !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* 
Icomoon font glyphs are used for our custom icons, 
they can be styled like text: font-size, color, etc.

Most icons are used only once, or styled the same way in each use,
so it is safe to apply style rules here, and then override in the
rare case they need to be different.

If new glyphs are added/glyphs are changed: update the css vars 
in /styles/icons.css, and define the appropriate::after selector 
here, apply icon using "name-icon" class name in conjunction 
with "icon-moon" class name.
 */

/* Current in-use icons */
.star-empty-icon::after {
    content: var(--star-empty);
    color: var(--dark-theme-icon-color);
}

.star-dashed-icon::after {
    content: var(--star-dashed);
    color: var(--dark-theme-star-icon-active-color);
}

.star-full-icon::after {
    content: var(--star-full);
    color: var(--dark-theme-star-icon-active-color);
}

.step-back-icon::after {
    content: var(--step-back);
}

.step-forward-icon::after {
    content: var(--step-forward);
}

.looping-icon::after {
    font-size: 13px;
    content: var(--looping);
}

.rotate-icon::after {
    font-size: 10px;
    content: var(--rotate);
}

.pan-icon::after {
    font-size: 6px;
    content: var(--pan);
}

.focus-icon::after {
    content: var(--focus);
}

.orthographic-icon::after {
    font-size: 14px;
    content: var(--orthographic);
}

.perspective-icon::after {
    font-size: 14px;
    content: var(--perspective);
}

.reset-icon::after {
    content: var(--reset);
}

.close-icon::after {
    font-size: 12px;
    color: var(--light-theme-modal-close-icon);
    content: var(--close);
}

.stop-record-icon::after {
    font-size: 9px;
    content: var(--stop-record);
}

/* the following rules stack two glyphs that
need different colors at different times */
.record-icon-circle,
.record-icon-ring {
    position: absolute;
    left: 0;
    right: 0;
}
.record-icon-ring::after {
    font-size: 12px;
    content: var(--start-record-ring);
}

.record-icon-circle::after {
    font-size: 12px;
    content: var(--start-record-circle);
}
