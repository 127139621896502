:root {
    --black: #000000;
    --dark: #141219;
    --dark-two: #1e1b25;
    --dark-three: #25222e;
    --dark-four: #2f2a3c;
    --dark-five: #3c384a;
    --pure-white: #ffffff;
    --off-white: #f7f7f7;
    --white-two: #d3d3d3;
    --white-three: #d8d8d8;
    --white-four: #f2f2f2;
    --transparent-white: rgba(255, 255, 255, 0.65);
    --transparent-black: rgba(0, 0, 0, 0.85);
    --dim-gray: #383838;
    --dim-gray-two: #6e6e6e;
    --purplish-gray: #4a4658;
    --pale-gray: #ddd9ec;
    --heather: #bab5c9;
    --text-gray: #a0a0a0;
    --grayish-brown: #4a4a4a;
    --warm-gray: #979797;
    --charcoal-gray: #3b3649;
    --slate-gray: #4b4b4b;
    --light-purple: #e7e4f2;
    --light-purple-two: #d7c8ff;
    --baby-purple: #b59ff6;
    --allen-purple: #8d87aa;
    --blue: #0094ff;
    --baby-purple-two: #dccfff;
    --medium-gray: #aeaeae;
    --dark-blue-gray: #69738a;
    --brick-red: #d14040;
    --bright-red: #c23030;
    /* non-theme variables */
    --star-checkbox-bg: var(--grayish-brown);
    --footer-bg: var(--dark-three);
    --footer-text: var(--warm-gray);
    --footer-text-border-bottom: var(--grayish-brown);
    --color-picker-tooltip: var(--dark-blue-gray);
    --cancel-icon-color: var(--brick-red);
    /* Dark theme */
    /* dark theme dropdown menu */
    --dark-theme-dropdown-menu-bg: var(--charcoal-gray);
    --dark-theme-dropdown-menu-item-bg: var(--charcoal-gray);
    --dark-theme-dropdown-menu-item-color: var(--transparent-white);
    --dark-theme-dropdown-menu-item-focus-outline: var(--light-purple-two);
    --dark-theme-dropdown-menu-item-focus-bg: var(--light-purple-two);
    --dark-theme-dropdown-menu-item-focus-color: var(--dark-two);
    --dark-theme-dropdown-menu-item-hover-bg: var(--light-purple-two);
    --dark-theme-dropdown-menu-item-hover-color: var(--dark-two);
    /* viewport-buttons */
    --dark-theme-viewport-button-bg: var(--dark-three);
    --dark-theme-viewport-button-border: var(--dark-three);
    --dark-theme-viewport-button-color: var(--white-three);
    --dark-theme-viewport-button-toggle-border: var(--dim-gray-two);
    --dark-theme-viewport-button-hover-bg: var(--purplish-gray);
    --dark-theme-viewport-button-disabled-color: var(--dim-gray-two);
    /* main buttons */
    --dark-theme-primary-button-focus-outline: var(--light-purple-two);
    /* --dark-theme-primary-button-bg: var(--baby-purple);
    /* dark theme general/uncategorized */
    --dark-theme-record-button-red: var(--bright-red);
    --dark-theme-body-bg: var(--black);
    --dark-theme-header-bg: var(--dark-three);
    --dark-theme-text-color: var(--off-white);
    --dark-theme-text-color-bright: var(--pure-white);
    --dark-theme-dim-color: var(--text-gray);
    --dark-theme-slider-handle-color: var(--white-three);
    --dark-theme-primary-color: var(--baby-purple);
    --dark-theme-header-text: var(--text-gray);
    --dark-theme-component-bg: var(--dark-four);
    --dark-theme-input-border: var(--white-two);
    --dark-theme-popover-text: var(--white-two);
    --dark-theme-menu-text-color: var(--transparent-white);
    --dark-theme-menu-text-hover: var(--pure-white);
    --dark-theme-agent-dropdown-toggle: var(--warm-gray);
    --dark-theme-agent-dropdown-toggle-hover: var(--pure-white);
    --dark-theme-sidebar-bg: var(--dark);
    --dark-theme-sidebar-header-bg: var(--dark-three);
    --dark-theme-sidebar-item-bg: var(--dark-two);
    --dark-theme-sidebar-text: var(--white-two);
    --dark-theme-sidebar-text-dim: var(--medium-gray);
    --dark-theme-btn-hover-bg: var(--grayish-brown);
    --dark-theme-tooltip-bg: var(--charcoal-gray);
    --dark-theme-version-badge-purple: var(--dark-five);
    --dark-theme-icon-color: var(--warm-gray);
    --dark-theme-star-icon-active-color: var(--white-two);
    --dark-theme-divider: var(--white-two);
    --dark-theme-divider-dim: var(--dim-gray-two);
    /* Light theme */
    /* general */
    --light-theme-bg: var(--light-purple);
    --light-theme-color: var(--transparent-black);
    --light-theme-white-bg: var(--pure-white);
    --light-theme-gray-bg: var(--white-four);
    --light-theme-faint-text: var(--white-two);
    --light-theme-heading1-color: var(--dark-three);
    --light-theme-heading2-color: var(--grayish-brown);
    --light-theme-checkbox-color: var(--dark-three);
    --light-theme-blue-text: var(--blue);
    --light-theme-divider: var(--heather);
    /* modal colors */
    --light-theme-modal-bg: var(--light-theme-bg);
    --light-theme-modal-color: var(--dark);
    --light-theme-modal-supplemental-text: var(--charcoal-gray);
    --light-theme-modal-close-icon: var(--warm-gray);
    --light-theme-modal-close-icon-hover: var(--white-three);
    --light-theme-modal-divider: var(--heather);
    --light-theme-modal-input-bg: var(--white-four);
    --light-theme-modal-input-color: var(--dark);
    --light-theme-modal-input-border: var(--heather);
    --light-theme-modal-disabled-bg: transparent;
    --light-theme-modal-input-disabled-color: var(--medium-gray);
    /* primary modal buttons */
    --light-theme-btn-primary-bg: var(--dark-four);
    --light-theme-btn-primary-border: var(--dark-four);
    --light-theme-btn-primary-color: var(--white-three);
    --light-theme-btn-primary-hover-bg: var(--dark);
    --light-theme-btn-primary-hover-border: var(--dark);
    --light-theme-btn-primary-hover-color: var(--baby-purple);
    --light-theme-btn-primary-disabled-bg: var(--heather);
    --light-theme-btn-primary-disabled-border: var(--heather);
    --light-theme-btn-primary-disabled-color: var(--white-three);
    /* secondary modal buttons */
    --light-theme-btn-secondary-bg: transparent;
    --light-theme-btn-secondary-border: var(--dark-four);
    --light-theme-btn-secondary-color: var(--dark-four);
    --light-theme-btn-secondary-hover-bg: var(--dark);
    --light-theme-btn-secondary-hover-border: var(--dark);
    --light-theme-btn-secondary-hover-color: var(--baby-purple);
    --light-theme-btn-secondary-disabled-bg: transparent;
    --light-theme-btn-secondary-disabled-border: var(--heather);
    --light-theme-btn-secondary-disabled-color: var(--heather);
    /* misc light theme components */
    --light-theme-card-bg-white: var(--light-theme-white-bg);
    --light-theme-version-badge-purple: var(--allen-purple);
    --light-theme-visual-glossary-header-bg: var(--pale-gray);
    --light-theme-visual-glossary-content-bg: var(--off-white);
}
