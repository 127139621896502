.container {
    height: 100%;
}

.container :global(.ant-card) {
    padding-right: 10px;
}

.container :global(.ant-card-body) {
    padding: 0 0 0 10px;
}