.sider {
    height: calc(100vh - var(--header-height));
    left: 0;
    z-index: 100;
    background-color: var(--dark-theme-sidebar-bg);
}
.sider.embed {
    height: 100vh;
}

.sider.left.collapsed {
    padding: 0 10px 0 0;
}

.sider.right {
    right: 0;
    left: inherit;
    padding: 0 0 0 10px;
}

.sider.left {
    left: 0;
    right: inherit;
    padding: 0 0 10px;
}

.sider :global(.ant-collapse-content-box) {
    padding: 3px 0;
}
.sider.right :global(.ant-collapse-content-box) {
    padding: 0;
}

.sider :global(.ant-layout-sider-children) {
    overflow: auto;
    overflow-x: hidden;
    overflow-y: auto;
}

:global(.ant-collapse),
:global(.ant-collapse-header),
:global(.ant-collapse > .ant-collapse-item:last-child),
:global(.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header) {
    border-radius: 0;
}

.sider :global(.ant-collapse) {
    border-top: 0;
    border-left: 0;
}

.sider i {
    position: absolute;
    top: 50%;
    left: 3.5px;
}

.trigger {
    position: absolute;
    top: 3px;
    z-index: 200;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background-color: var(--dark-theme-component-bg);
    cursor: pointer;
    box-shadow: 0 1px 3px black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.trigger img {
    height: 22px;
    transition: all 0.4s;
}

.trigger.left {
    right: -22px;
}

.trigger.right {
    left: -22px;
}

.left img,
.collapsed.right img {
    transform: rotate(180deg);
}

.collapsed.left img,
.right img {
    transform: rotate(0deg);
}

@media screen and (max-width: 590px) {
    .sider {
        display: none;
    }
}
