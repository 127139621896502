.viewport-button:global(.ant-btn),
.viewport-button:global(.ant-btn:focus) {
    background-color: var(--dark-theme-viewport-button-bg);
    border-color: var(--dark-theme-viewport-button-border);
    border-radius: 3px;
    color: var(--dark-theme-viewport-button-color);
    height: 26px;
    width: 26px;
    min-width: 26px;
}

.viewport-button:global(.ant-btn).disabled {
    color: var(--dark-theme-viewport-button-disabled-color);
    border-color: var(--dark-theme-viewport-button-bg);
    cursor: not-allowed;
}

.viewport-button:global(.ant-btn).active {
    border-color: var(--dark-theme-viewport-button-toggle-border);
}

.viewport-button:global(.ant-btn:hover):not(.disabled) {
    background-color: var(--dark-theme-viewport-button-hover-bg);
    border-color: var(--dark-theme-viewport-button-bg);
    color: var(--dark-theme-viewport-button-color);
}

.viewport-button:global(.ant-btn:hover):not(.disabled).active {
    border-color: var(--dark-theme-viewport-button-toggle-border);
}

.viewport-button:global(.ant-btn).radio-group-top {
    border-radius: 3px 3px 0px 0px;
}

.viewport-button:global(.ant-btn).radio-group-bottom {
    border-radius: 0px 0px 3px 3px;
}

.viewport-button:global(.ant-btn) :global(.record-icon-circle),
.viewport-button:global(.ant-btn) :global(.stop-record-icon) {
    color: var(--dark-theme-record-button-red);
}

.viewport-button:global(.ant-btn).disabled :global(.record-icon-circle) {
    color: var(--dark-theme-viewport-button-disabled-color);
}
