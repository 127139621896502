.menu-wrapper {
    width: fit-content;
    background-color: var(--dark-theme-dropdown-menu-bg);
    border-radius: 3px;
    padding: 4px;
}

.menu {
    background-color: inherit;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.menu-wrapper .menu :global(.ant-dropdown-menu-submenu-title),
.menu-wrapper .menu :global(.ant-dropdown-menu-item-only-child) {
    padding: 0px;
}
