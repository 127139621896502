@import '../../styles/constants.css';

.page-header {
    position: relative;
    background-color: var(--dark-theme-header-bg);
    color: var(--dark-theme-text-color);
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 3px 3px var(--dark);
    z-index: 200;
    height: var(--header-height);
    padding: 0 10px;
}

.page-header img {
    padding: 4px;
}

.page-header a {
    color: var(--dark-theme-primary-color);
}

.left-links {
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin-right: 2em;
}

.left-links img {
    border-radius: 6px;
    vertical-align: middle;
}

.left-links a:focus-visible img {
    outline: 1px solid var(--dark-theme-primary-button-focus-outline);
}

.home {
    margin-left: 2px;
    margin-right: 20px;
    font-weight: 400;
    font-size: 12px;
}

.vertical-bar {
    display: inline-block;
    margin-right: 4px;
    margin-left: 8px;
    color: var(--dark-theme-header-text);
    font-size: 16px;
    font-weight: 200;
}

.pipe {
    padding-left: 2px;
    color: var(--text-gray);
}

/* For large screens only */
@media screen and (min-width: 768px) {
    .page-header {
        padding: 0 20px;
    }

    .buttons {
        min-width: 305px;
    }
}

/* For small-med tablet in portrait mode or narrower */
@media screen and (max-width: 768px) {  
    /* Hide the Load Model button and the loaded trajectory name */
    .page-header .viewer-title,
    .page-header :global(.ant-btn-primary) {
        display: none;
    }

    .page-header :global(.ant-btn-ghost) {
        margin: 0;
    }
}

/* For smartphone in portrait mode */
@media screen and (max-width: 480px) {
    /* Hide "| Simularium Home" */
    .vertical-bar, .simularium-home {
        display: none;
    }
}

.page-header .button-container {
    display: flex;
    align-items: center;
    gap: 16px;
}
