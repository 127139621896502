.container {
    position: absolute;
    height: calc(100vh - var(--header-height));
    width: 100%;
    z-index: 300;
    background-color: var(--light-theme-bg);
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
}

.title {
    color: var(--light-theme-color);
    font-size: 24px;
    font-weight: 600;
}

.content {
    color: var(--light-theme-color);
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 10px 0 26px 0;
}

.go-back-button {
    margin-top: 10px;
}

.processing-indicator {
    margin-bottom: 40px;
}

.spin-container {
    margin: 20px 0;
    padding: 30px 50px 10px;
}

.new-instance-text {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0.5em;
}

.divider-container {
    width: 496px;
    margin-bottom: 26px;
}

.divider-container :global(.ant-divider) {
    background-color: var(--light-theme-divider);
}
