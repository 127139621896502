.container {
    color: var(--dark-theme-sidebar-text);
    padding: 16px 14px 16px 24px;
}

.collapsed {
    padding: 14px 8px 8px 16px;
}

.agent-selected {
    min-height: 265px;
}

.title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    cursor: pointer;
}

.container h3 {
    color: var(--dark-theme-text-color-bright);
    font-weight: 400;
    font-size: 16px;
}

.icon {
    background-color: transparent;
    border: none;
    color: var(--dark-theme-icon-color);
    font-size: 18px;
}

.title:hover .icon {
    color: var(--dark-theme-menu-text-hover);
}

.rotate span {
    transform: rotate(180deg);
}

.panel-contents {
    font-size: 12px;
    font-weight: 400;
}

.no-agent-row {
    display: flex;
    flex-direction: column;
    font-size: 12px;
}

.no-agent-row p {
    margin-bottom: 4px;
}

p.no-agent-heading {
    font-size: 14px;
}

p.no-agent-text {
    font-size: 12px;
}

.agent-missing-text {
    color: var(--dark-theme-sidebar-text-dim);
    font-size: 13px;
}

.row {
    display: flex;
    font-weight: 400;
}

.key {
    color: var(--dark-theme-sidebar-text-dim);
    min-width: 120px;
    align-self: flex-start;
}

.divider {
    background-color: var(--dark-theme-divider-dim);
    margin: 4px 0px;
}
