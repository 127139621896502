.card {
    border-radius: 6px;
    width: 400px;
    padding: 15px 15px 5px 15px;
    background-color: var(--light-theme-card-bg-white);
    border: none;
    display: flex;
    flex-direction: column;
}

.card :global(.ant-card-body) {
    padding: 0px;
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.thumbnail {
    display: grid;
}

.static-thumbnail,
.animated-thumbnail {
      grid-area: 1 / 1;
}

.animated-thumbnail {
    opacity: 0;
}

.thumbnail:hover .animated-thumbnail {
    opacity: 1;
}

.thumbnail:hover .static-thumbnail {
    opacity: 0;
}

.trajectory-description {
    font-size: 16px;
}

.stretch {
    flex-grow: 2;
}

.version-and-time {
    display: flex;
    justify-content: space-between;
    margin-top: 4px;
    margin-bottom: 8px;
    font-size: 16px;
}

.load-your-data {
    font-size: 16px;
    flex-grow: 2;
}

/* Necessary to make flexbox spacing work */
.version-tag,
.simulated-time {
    margin: 0 !important;
}

.version-tag {
    border-radius: 2px;
    padding-right: 3px;
    padding-left: 3px;
    height: 20px;
    font-size: 16px;
    color: white;
    border-color: var(--light-theme-version-badge-purple);
}

/* Remove Antd hover effect for Tags */
.version-tag:hover {
    opacity: 1;
}

.card-title {
    font-weight: 600;
}

.publication {
    font-size: 14px;
}

.journal {
    font-style: italic;
}

.legalese {
    font-size: 10px;
    color: var(--light-theme-faint-text);
}

.legalese a {
    color: var(--light-theme-faint-text);
    text-decoration: underline;
}